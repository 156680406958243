import React from "react"
import Layout from "../layouts/layout"

export default () => (
  <Layout>
    <div className="content">
      <p>
        In case you wanna send me a book recommendation or a gift certificate
        for prosecco disneyland or something.
      </p>
      <a href="mailto:ca.miller92@gmail.com">ca.miller92@gmail.com</a>
    </div>
  </Layout>
)
